import React, { useState, useEffect } from "react"
import Head from "@components/Head"
import MainLayout from "@components/main_layout"
import {
  Typography,
  Button,
  Table,
  Space,
  Popconfirm,
  Spin,
  message,
  Tooltip,
} from "antd"
import { SearchOutlined, UndoOutlined } from "@ant-design/icons"
import { transformDate } from "@action"
import { AppraisalClient, OtherClient, AppraisalCycleClient } from "@api"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { SearchContent } from "@components/SearchContent"
import { InactiveTag, ScoreProgressCircle } from "lib"

const MyTaskTable = () => {
  const [isInit, setIsInit] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [pageMeta, setPageMeta] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({
    cycleId: null,
    appraisalNumber: null,
    staffNo: null,
    staffName: null,
    templateType: null,
    siteId: null,
    positionId: null,
    orderBy: "appraisalNumber",
    isAscend: false,
    pageSize: 10,
    pageIndex: 1,
    isStaffInactiveExcluded: false,
    isStaffActiveExcluded: false,
    isStaffDisabledExcluded: false,
  })
  const [options, setOptions] = useState(null)

  const getData = async params => {
    setIsLoading(true)
    try {
      let client = new AppraisalClient()
      let res = await client.get(
        params.cycleId,
        false,
        params.appraisalNumber,
        params.staffNo,
        params.staffName,
        params.templateType,
        params.siteId,
        params.positionId,
        params.orderBy,
        params.isAscend,
        params.pageSize,
        params.pageIndex,
        false, //frontline
        null,
        null,
        params.isStaffInactiveExcluded,
        params.isStaffActiveExcluded,
        params.isStaffDisabledExcluded
      )
      setDataSource(res.items)
      setPageMeta({
        current: res.pageIndex,
        total: res.totalCount,
        pageSize: params.pageSize,
      })
      setIsInit(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const recallData = async (id, name) => {
    setIsLoading(true)
    try {
      let client = new AppraisalClient()
      await client.recall(id)
      message.success("Recall Appraisal" + name + "success !")
      getData(searchParams)
    } catch (err) {
      console.log(err)
      message.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getOptions = async () => {
      let client = new OtherClient()
      const cycleClient = new AppraisalCycleClient()
      try {
        let res = await client.get()
        const cycleList = await cycleClient.getDropdown()
        setOptions({ ...res.content, cycleList: cycleList })
      } catch (err) {
        console.log(err)
      }
    }

    getOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData(searchParams)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    {
      title: <FormattedMessage id="appraisalNo" />,
      dataIndex: "appraisalNumber",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalCycleName" />,
      dataIndex: "appraisalCycleName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraiseeName" />,
      dataIndex: "staffName",
      sorter: true,
    },
    {
      title: <FormattedMessage id="staffStatus" />,
      dataIndex: "isStaffEnabled",
      render: (value, { disableRemarks }) => (
        <InactiveTag value={value} remarks={disableRemarks} />
      ),
      sorter: true,
    },
    {
      title: <FormattedMessage id="position" />,
      dataIndex: "position",
      sorter: true,
      render: text => text ?? " - ",
    },
    {
      title: <FormattedMessage id="site" />,
      dataIndex: "siteOrDepartment",
      sorter: true,
    },

    {
      title: <FormattedMessage id="status" />,
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: <FormattedMessage id="appraisalPeriod" />,
      dataIndex: "appraisalStartDate",
      sorter: true,
      render: (_, record) =>
        transformDate(record.appraisalStartDate) +
        " to " +
        transformDate(record.appraisalEndDate),
    },
    {
      title: <FormattedMessage id="finalScore2" values={{ name: "" }} />,
      dataIndex: "finalScore",
      sorter: true,
      render: (finalScore, { scoreJSON, id }) => (
        <ScoreProgressCircle
          finalScore={finalScore}
          scoreJSON={scoreJSON}
          appraisalId={id}
        />
      ),
    },
    {
      title: <FormattedMessage id="action" />,
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/appraisal/${record.id}`}>
            <Button shape="round" size="small" icon={<SearchOutlined />}>
              <FormattedMessage id="view" />
            </Button>
          </Link>

          {record.displayRecall ? (
            record.hasRecall ? (
              <Popconfirm
                title={
                  <FormattedMessage
                    id="sureTo"
                    values={{ name: <FormattedMessage id="recall" /> }}
                  />
                }
                okText={<FormattedMessage id="ok" />}
                cancelText={<FormattedMessage id="cancel" />}
                onConfirm={() => {
                  recallData(record.id, record.appraisalNumber)
                }}
              >
                <Button shape="round" size="small" icon={<UndoOutlined />}>
                  <FormattedMessage id="recall" />
                </Button>
              </Popconfirm>
            ) : (
              <Tooltip title={<FormattedMessage id="cannotRecall" />}>
                <Button
                  shape="round"
                  size="small"
                  icon={<UndoOutlined />}
                  disabled={!record.hasRecall}
                >
                  <FormattedMessage id="recall" />
                </Button>
              </Tooltip>
            )
          ) : null}
        </Space>
      ),
    },
  ]

  const handleTableChange = (pagination, b, sorter, { action }) => {
    if (action === "paginate") {
      setSearchParams({
        ...searchParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      })
    } else if (action === "sort") {
      setSearchParams({
        ...searchParams,
        orderBy: sorter.field,
        isAscend: sorter.order === "ascend",
      })
    }
  }
  return !isInit && options ? (
    <>
      <SearchContent
        setSearchParams={values => setSearchParams(values)}
        options={options}
      />
      <Spin spinning={isLoading} size="large">
        <Table
          size="small"
          className="table"
          dataSource={dataSource}
          onChange={handleTableChange}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            ...pageMeta,

            position: ["bottomCenter"],
          }}
        />
      </Spin>
    </>
  ) : (
    <Spin size="large" />
  )
}

const MySubmission = () => {
  const { Title } = Typography

  return (
    <MainLayout>
      <Head id="mySubmissionTitle" />
      <Title level={3}>
        <FormattedMessage id="mySubmissionTitle" />
      </Title>

      <MyTaskTable />
    </MainLayout>
  )
}

export default MySubmission
